


















































































































































































import { Component, Vue } from 'vue-property-decorator';
import RoundRobin from '@/lib/Round-robin';
import Player from '@/models/Player';
import Member from '@/models/Member';
import TournamentModel from '@/models/TournamentModel';
import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/database';

import LoginStore from '@/lib/LoginStore';

@Component({
  components: {
  },
})
export default class V1 extends Vue {
  public name = '';
  public tournamentName = '';
  public loginState = LoginStore.LoginState;
  public tournamentModel = new TournamentModel();
  
  public result = ['', '勝ち', '負け', 'CO'];

  public database = firebase.database();

  public saveErrorMessage = '';

  private CreateMacthList() {
    const roundRobin = new RoundRobin();
    this.tournamentModel.MatchList = roundRobin.CreatePair(this.tournamentModel.NameList);
  }

  public SaveData(): void {
    if(firebase.auth().currentUser != null) {
      if(this.tournamentName != '' && this.tournamentModel != null) {
        const userId = firebase.auth().currentUser?.uid;
        this.tournamentModel.TournamentType = 1;
        this.tournamentModel.Lane = 2;
        this.tournamentModel.TournamentName = this.tournamentName;

        this.database.ref('tournaments/' + userId).push(this.tournamentModel).then(() => {
          this.$bvModal.show('saveModal');
        }).catch((error) => {
          this.$bvModal.show('saveErrorModal');
          this.saveErrorMessage = error.message;
        });
      } else {
        this.$bvModal.show('saveErrorModal');
      }
    }
  }

  public AddName(): void {
    if(this.name != '') {
      const member = new Member(this.name);
      this.tournamentModel.NameList.push(member);
      this.name = '';

      if(this.tournamentModel.NameList.length > 1) {
        this.CreateMacthList();
      } else {
        this.tournamentModel.MatchList = new Array<Array<Player>>();
      }

      this.tournamentModel.SortedNameList = this.tournamentModel.NameList.concat();
    }
  }

  public RemoveName(index: number): void {
    this.tournamentModel.NameList.splice(index, 1);
    this.tournamentModel.SortedNameList = this.tournamentModel.NameList.concat();

    if(this.tournamentModel.NameList.length > 1) {
        this.CreateMacthList();
      } else {
        this.tournamentModel.MatchList = new Array<Array<Player>>();
      }
  }

  public CalcPoint(): void {
    const calcArray: {[key: string]: number} = {};

    this.tournamentModel.NameList.forEach((element) => {
      calcArray[element.Name] = 0;
    });

    this.tournamentModel.MatchList.forEach((matchCards) => {
      matchCards.forEach((matchCard) => {
        const playerName = matchCard.Name;
        console.log(`player1Point: ${matchCard.Point} player2Point: ${matchCard.Point}`);
        calcArray[playerName] += matchCard.Point != undefined ? this.tournamentModel.PointSetting[matchCard.Point] : 0;
      });
    });

    this.tournamentModel.NameList.forEach((element) => {
      const totalPpint = calcArray[element.Name];
      element.TotalPoint = totalPpint;
    });

    this.tournamentModel.NameList.sort(function(a: Member, b: Member) {
        return (a.TotalPoint > b.TotalPoint) ? -1 : 1;
    });
  }
}
